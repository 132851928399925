<template>
  <div class="breadcrumb" style="transform: translateX(-30px);">
    <a href="" style="background-color: white; visibility: hidden;">
      <span class="breadcrumb__inner">
        <span class="breadcrumb__title"> </span>
      </span>
    </a>
    <a href="" style="background-color: #24536D" @click="preventClick">
      <span class="breadcrumb__inner">
        <span class="breadcrumb__title">EPC</span>
      </span>
    </a>
    <a href="" :style="{ backgroundColor: labelColor }" @click="preventClick">
      <span class="breadcrumb__inner">
        <span class="breadcrumb__title">{{ labelValue }}</span>
      </span>
    </a>
    <a href="" style="background-color: white; visibility:hidden;">
      <span class="breadcrumb__inner">
        <span class="breadcrumb__title"> </span>
      </span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'EPCLabel',
  props: ['epcLabel'],
  data () {
    return {}
  },
  methods: {
    preventClick (e) {
      e.preventDefault()
    }
  },
  computed: {
    labelColor () {
      switch (this.epcLabel) {
        case 1:
          return '#258643'
        case 2:
          return '#52A346'
        case 3:
          return '#C5D02A'
        case 4:
          return '#EED216'
        case 5:
          return '#FAB64B'
        case 6:
          return '#F07F31'
        case 7:
          return '#DE3133'
        case 8:
          return '#DE3133'
        case 9:
          return '#848484'
        default:
          return '#946DDB'
      }
    },
    labelValue () {
      switch (this.epcLabel) {
        case 1:
          return 'A+'
        case 2:
          return 'A'
        case 3:
          return 'B'
        case 4:
          return 'C'
        case 5:
          return 'D'
        case 6:
          return 'E'
        case 7:
          return 'F'
        case 8:
          return 'G'
        case 9:
          return 'X'
        default:
          return '?'
      }
    }
  }
}
</script>

<style scoped>
  .breadcrumb {
    display: -webkit-box;
    display: flex;
    overflow: hidden;
    margin: 0;
    text-align: center;
    top: 50%;
    width: 160px;
    height: 18px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
    background-color: transparent;
    font-size: 14px;
  }

  .breadcrumb a {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
    flex-grow: 1;
    text-decoration: none;
    margin: auto;
    height: 100%;
    padding-left: 10px;
    padding-right: 0;
    color: white;
    cursor:default;
  }

  .breadcrumb a:first-child {
    padding-left: 15.2px;
  }

  .breadcrumb a:last-child {
    padding-right: 15.2px;
  }

  .breadcrumb a:after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 18px;
    height: 18px;
    top: 0;
    right: -9px;
    background-color: inherit;
    border-top-right-radius: 5px;
    -webkit-transform: scale(0.707) rotate(45deg);
    transform: scale(0.707) rotate(45deg);
    box-shadow: 1px -1px rgba(0, 0, 0, 0.25);
    z-index: 1;
    visibility: visible;
  }

  .breadcrumb a:last-child:after {
    content: none;
  }

  .breadcrumb__inner {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    margin: auto;
    z-index: 2;
  }

  .breadcrumb__title {
    font-weight: bold;
  }
</style>
